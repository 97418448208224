<template>
  <div>
    <backToHomeButton :title="backToHomeButtonText" v-if="!panels.infoWrapper && showHome" />
    <div class="top-menu intro11"  :class="{'light' : current && ((!current.menuColor || !current.menuColor.length) || (current.menuColor.length && current.menuColor[0] != 'indigo'))}">
      <ul>
        <template v-for="(item, i) in slides">
          <li :key="'key-menu-' + i" v-if="item.menuTitle && (item.showOnTopMenu || item.parent)">
            <a href="#" v-if="item.showOnTopMenu" @click.stop.prevent="handleMenu(item, i)"
            :class="{ 'active' : slides[currentSlide] && slides[currentSlide].parentId == item.parentId }">{{item.menuTitle}}</a>
            <div class="top-menu-sub-list" v-if="dropdownMenu.show && dropdownMenu.list.length > 0 && dropdownMenu.index == i">
              <div class="d-flex justify-content-end">
                <span class="menu-close-icon" @click="closeMenu()">
                  <img :src="require('@/assets/images/cancel-white.svg')" alt="">
                </span>
              </div>
              <ul>
                <li v-for="(ditem, index) in dropdownMenu.list" :key="'sub-menu-' + index">
                  <a @click="handleSubMenu(parseInt(ditem.realIndex))" v-text="ditem.menu_title" :class="{'active': slides[currentSlide] && slides[currentSlide].id == ditem.id}"></a>
                </li>
              </ul>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div class="app-inner">
      <div class="slides-wrapper slides-wrapper-intro11 intro11 clip" @click.stop="changeSlides">
        <template v-for="(item, index) in slides">
          <div :key="'a-slide' + index" class="a-slide" :class="[{
            'animate' : (animate && currentSlide == index),
            'active': currentSlide == index,
            'preSlide': index < currentSlide,
            'slideUp': !item.fade,
            'lastType': slides[(index + 1)] && slides[(index + 1)].type === 2,
            'counter-slide': item.counterChild,
            'counter-slide-parent': item.csParent,
            'singleImageContent': item.singleImage,
            'slide-type-3-second': item.type == 3 && !item.slides
          },
          'slide-type-' + item.type,
          item.contentPosition]"
          :style="{
            backgroundImage : 'url(' + baseUrl + item.img + ')'
          }" >
            <div
              v-if="(item.type === 1 && !item.childSlide) || item.type === 9"
              class="a-slide-up-cont"
              :class="{
                'main': item.contentPosition === 'center',
                'halfTopSpace': item.contentPosition !== 'center' && item.type === 1,
                ['item-child-slide-' + item.nr]: [item.childSlide],
                'mt-8 pt-6': item.type === 9
              }"
            >
              <div v-if="item.counterChild" class="child-nr"><span v-text="item.nr"></span></div>
              <h1 v-if="item.title" :class="{'text-primary': item.type === 9}" v-html="item.title"></h1>
              <h2 v-if="item.subTitle" v-html="item.subTitle"></h2>
              <div v-if="item.counterChild" class="dot-separator">
                <ul>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
            <div
              v-if="(item.type === 1 && !item.childSlide) || item.type === 9"
              class="a-slide-down-cont"
              :class="[{
              'halfSpace': item.contentPosition !== 'center' && item.subTitle,
              'hasSlides': item.hasSlides,
              ['item-child-slide-' + item.nr]: [item.childSlide],
              'mt-1': item.type === 9
              }]"
            >
              <ul class="listImage" v-if="item.sponsImg && item.sponsImg.length > 0">
                <template v-for="(itm, i) in item.sponsImg">
                  <li :key="'spimg' + i" v-if="item.hasSlides" @click.stop.prevent="handleInnerSlides(itm, i)">
                    <img v-if="itm.img" class="sponsBy" :src="(baseUrl + itm.img)" alt="">
                    <span v-if="itm.title" v-text="itm.title"></span>
                  </li>
                  <li :key="'spimg' + i" v-else>
                    <img v-if="itm.img" class="sponsBy" :src="(baseUrl + itm.img)" alt="">
                    <span v-if="itm.title" v-text="itm.title"></span>
                  </li>
                </template>
              </ul>
              <speed v-if="item.hasProgressCharts && currentSlide == index" :item="item"></speed>
              <SlideVideos
              v-if="item.type === 9 && item.slide_video_box.length"
              :videos="item.slide_video_box"
              :baseUrl="baseUrl"
              @toggleVideo="openSVideo"/>
              <button class="btn" v-if="item.nextButton">
                <transition name="fade-in">
                  <span class="btn-number" v-if="(item.counterChild && slides[parseInt(currentSlide) + 1] && slides[parseInt(currentSlide) + 1].counterChild)" v-text="(item.nr + 1)"></span>
                </transition>
                <span class="btn-text" v-text="item.nextButtonText"></span>
                <arrow-down></arrow-down>
              </button>
            </div>
            <div class="a-slide-title-type" v-if="item.type === 2">
              <div v-html="item.title">
              </div>
            </div>
            <vorteile-child v-if="item.childSlide" :item="item"></vorteile-child>
            <anschluss-slide v-if="item.type === 3" :item="item" @triggerTipp="tippDialog = true"></anschluss-slide>
            <div v-if="item.type === 4" class="iframe-view">
              <iframe sandbox="allow-same-origin allow-scripts" v-show="index == currentSlide && item.iframe_url"  :src="item.iframe_url" frameborder="0"></iframe>
                <a href="#" :class="{ 'visible' : index == currentSlide && item.iframe_url }" @click="changeSlides"
          class="continue-button text-primary continue-button-intro-10">weiter</a>
            </div>
            <marketing-slide v-if="item.type === 20" :item="item" :baseUrl="baseUrl" @changeSlides="changeSlides"></marketing-slide>
            <div class="hasPopup" v-if="item.hasPopup && item.hasPopup.title && currentSlide == index" @click.stop.prevent="handlePopupDialog(item.hasPopup)">
              <p v-text="item.hasPopup.title"></p>
              <figure>
                <img v-if="item.hasPopup && item.hasPopup.image_icon" :src="baseUrl + item.hasPopup.image_icon" />
              </figure>
            </div>
          </div>
        </template>
      </div>
      <transition name="slide-left">
        <vorteile-sidebar
        v-if="slides[currentSlide] && slides[currentSlide].childSlide"
        :item="slides[vorteileIndex]"
        :index="vorteileIndex"
        @changeSlide="changeSlides"
        :active="slides[currentSlide]"></vorteile-sidebar>
      </transition>
      <transition name="fade-in">
        <slide-popup v-if="popupDialog" :item="popupDialog" @handlePopup="handlePopupDialog"></slide-popup>
      </transition>
      <transition name="fade-in">
        <tipp v-if="tippDialog && slides[currentSlide] && slides[currentSlide].hasTipp" :tipp="slides[currentSlide].hasTipp" @triggerTipp="tippDialog = false"></tipp>
      </transition>
    </div>
    <SlideVideosPlayer
      v-if="slideVideo && current && current.type === 9"
      :slideVideo="slideVideo"
      :baseUrl="baseUrl"
      @toggleVideo="openSVideo" />
    <sideMenu
      :isOpened="panels.sideMenu"
      v-on:togglePanel="togglePanel"
      v-on:handleIntroVideo="handleIntroVideo"
      :slides="slides"
      :currentSlide="currentSlide"
      :hasIntro="false"
      :intro="intro"
      v-on:changeSlides="changeSlides"></sideMenu>
    <infoWrapper
      v-if="showInfoBox"
      :isOpened="panels.infoWrapper"
      v-on:togglePanel="togglePanel"
      :tabs="infoBoxData"></infoWrapper>
    <bottomVideos
      :isOpened="panels.bottomVideos"
      v-on:togglePanel="togglePanel"></bottomVideos>
      <videoWrapper
      v-if="current && current.video_box && current.video_box.length >= 1"
      :isOpened="panels.videoWrapper"
      :videos="current.video_box || null"
      v-on:togglePanel="togglePanel"></videoWrapper>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import slidesStore from '@/store/modules/slides'
import infoWrapper from '@/components/infoWrapper'
import sideMenu from '@/components/sideMenu'
import bottomVideos from '@/components/bottomVideos'
import backToHomeButton from '@/components/backToHomeButton'
import arrowDown from '@/components/icons/arrow-down'
import vorteileChild from '@/components/intro11/vorteile-child'
import anschlussSlide from '@/components/intro11/anschluss-slide'
import vorteileSidebar from '@/components/intro11/vorteile-sidebar'
import speed from '@/components/intro11/speed'
import slidePopup from '@/components/intro11/slide-popup'
import tipp from '@/components/intro11/tipp'
import videoWrapper from "@/components/videoWrapper"
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import marketingSlide from '@/components/marketingSlide'
import SlideVideos from '@/components/intro16-hybrid/SlideVideos'
import SlideVideosPlayer from '@/components/intro16-hybrid/SlideVideosPlayer'

export default {
  name: 'app',
  mixins: [
    dynamicModule('slides', slidesStore)
  ],
  computed: {
    ...mapState('slides', ['baseUrl', 'intro', 'introInfoPage', 'slides'])
  },
  watch: {
    current (nv, val) {
      this.initEvents(nv && (nv.type !== 4 || nv.type !== 9))
    },
    currentSlide (nv, ov) {
      if (nv !== null) {
        switch (nv) {
          case 0:
            this.handleInfoBox(this.introInfoPage || null)
            break
          default:
            this.handleInfoBox(this.current?.info_page || null)
            break
        }
      }
    }
  },
  data () {
    return {
      currentSlide: 1,
      animate: true,
      showLoader: false,
      digitalCheckout: false,
      backToHomeButtonText: 'Glasfaser',
      vorteileIndex: null,
      popupDialog: null,
      tippDialog: null,
      dropdownMenu: {
        show: false,
        index: null,
        list: []
      },
      dropdownMenuList: [],
      isProduction: process.env.NODE_ENV === 'production',
      current: null,
      showIntro: false,
      showHome: true,
      isAnimating: false,
      imageCache: [],
      isVideoPlaying: true,
      panels: {
        'sideMenu': false,
        'infoWrapper': false,
        'bottomVideos': false,
        'videoWrapper': false
      },
      xDown: null,
      yDown: null,
      slideVideo: false,
      showInfoBox: false,
      infoBoxData: null,
      isSliderActive: false,
      showSplash: false,
      sliderOptions: {
        effect: 'slide',
        grabCursor: true,
        slidesPerView: 1,
        centerSlides: true,
        centeredSlidesBounds: true,
        infinite: true,
        navigation: {
          nextEl: '.swiper-button-next-u',
          prevEl: '.swiper-button-prev-u'
        },
        loop: true
      }
    }
  },
  components: {
    infoWrapper,
    sideMenu,
    bottomVideos,
    backToHomeButton,
    Swiper,
    SwiperSlide,
    arrowDown,
    speed,
    vorteileChild,
    anschlussSlide,
    vorteileSidebar,
    slidePopup,
    tipp,
    videoWrapper,
    marketingSlide,
    SlideVideos,
    SlideVideosPlayer
  },
  methods: {
    ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
    handleIntroVideo () {
      this.showIntro = true
      this.currentSlide = 0
      // this.current = null
      let video = this.$refs.$video
      if (video) {
        let playingState = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
        if (playingState) {
          video.pause()
          this.isVideoPlaying = false
        } else {
          video.play()
          this.isVideoPlaying = true
        }
      }
    },
    hideIntro (changeSlide = true) {
      this.currentSlide = 0
      this.changeSlides()
    },
    changeSlides (id) {
      // debugger
      if (this.isAnimating || this.isSliderActive) {
        return false
      }
      if (id instanceof MouseEvent) id = null

      if (this.current && this.current.innerSlider && !this.isSliderActive) {
        this.isSliderActive = true
        return
      }

      this.isAnimating = true
      this.showLoader = true
      this.animate = true


      setTimeout(() => {
        this.showLoader = false
        this.showIntro = false
        if (id === undefined || id === null) {
          this.currentSlide++
        } else if (typeof id === 'string' && ['r', 'l', 'u', 'd'].indexOf(id) > -1) {
          if (id === 'l' || id === 'u') this.currentSlide++
          else this.currentSlide--
        } else this.currentSlide = id
        if (this.currentSlide > (Object.keys(this.slides).length) || this.currentSlide === 0) this.handleIntroVideo()
        this.current = this.currentSlide === 0 ? this.slides[0] : this.slides[this.currentSlide]

        setTimeout(() => {
          this.animate = false
          this.isAnimating = false
          if (this.$route.params?.id) {
            this.addLoading(false)
          }
        }, 500)
      }, 300)
    },
    togglePanel (panelName, value) {
      for (let item in this.panels) {
        if (item === panelName) this.panels[panelName] = !value
        else this.panels[item] = false
      }
    },
    getTouches (evt) {
      return evt.touches || evt.originalEvent.touches
    },
    handleTouchStart (evt) {
      const firstTouch = this.getTouches(evt)[0]
      this.xDown = firstTouch.clientX
      this.yDown = firstTouch.clientY
    },
    handleTouchMove (evt) {
      let $this = this
      if (!this.xDown || !this.yDown || $this.isAnimating) {
        return
      }

      var xUp = evt.touches[0].clientX
      var yUp = evt.touches[0].clientY

      var xDiff = this.xDown - xUp
      var yDiff = this.yDown - yUp

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          $this.changeSlides('l')
        } else {
          $this.changeSlides('r')
        }
      } else {
        if (yDiff > 0) {
          $this.changeSlides('u')
        } else {
          $this.changeSlides('d')
        }
      }
      /* reset values */
      this.xDown = null
      this.yDown = null
    },
    wheelFunction (e) {
      let $this = this
      var dir = Math.sign(e.deltaY)

      if (!$this.isAnimating) {
        if (dir === -1) {
          $this.changeSlides('r')
        } else {
          $this.changeSlides('l')
        }
      }
      e.preventDefault()
      e.stopPropagation()
      return false
    },
    initEvents (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        ele.addEventListener('touchstart', $this.handleTouchStart, false)
        ele.addEventListener('touchmove', $this.handleTouchMove, false)
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        ele.removeEventListener('touchstart', $this.handleTouchStart, false)
        ele.removeEventListener('touchmove', $this.handleTouchMove, false)
        // ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    removeWheelEvent (bool) {
      let $this = this

      let ele = document.querySelector('.slides-wrapper')
      if (bool) {
        // ele.addEventListener('touchstart', $this.handleTouchStart, false)
        // ele.addEventListener('touchmove', $this.handleTouchMove, false)
        ele.addEventListener('wheel', $this.wheelFunction)
      } else {
        // ele.removeEventListener('touchstart', $this.handleTouchStart, false)
        // ele.removeEventListener('touchmove', $this.handleTouchMove, false)
        ele.removeEventListener('wheel', $this.wheelFunction)
      }
    },
    openVideo (url) {
      this.slideVideo = (url === undefined) ? '://0' : this.baseUrl + url
    },
    openSVideo (video) {
      this.slideVideo = video || false
    },
    handleInfoBox (data) {
      this.infoBoxData = data?.content_elements || null
      this.showInfoBox = data?.content_elements?.length > 0
    },
    handleInnerSlides (item, index) {
      this.changeSlides(parseInt(this.currentSlide) + index + 1)
    },
    handleVorteile () {
      for (let item in this.slides) {
        if (this.slides[item] && this.slides[item].hasSlides) {
          this.vorteileIndex = parseInt(item)
          break
        }
      }
    },
    handlePopupDialog (item) {
      this.popupDialog = item
    },
    handleTipp (item) {
      this.tippDialog = item
    },
    handleMenu (item, index) {
      if (this.dropdownMenu.show && index == this.dropdownMenu.index) {
        this.changeSlides(index)
        this.dropdownMenu = {
          index: null,
          list: [],
          show: false
        }
        return
      } else {
        this.dropdownMenu = {
          index: null,
          list: [],
          show: false
        }
        if (item.parent) {
          this.dropdownMenu.index = index
          for (let key in this.slides) {
            if (this.slides[key] && this.slides[key].parentId == item.id && this.slides[key].parentId != this.slides[key].id) {
              let item = this.slides[key]
              item.realIndex = key
              this.dropdownMenu.list.push(item)
            }
          }
          this.dropdownMenu.show = true
        }
      }
    },
    closeMenu () {
      this.dropdownMenu = {
        index: null,
        list: [],
        show: false
      }
    },
    handleSubMenu (index) {
      this.changeSlides(index)
      this.closeMenu()
    }
  },
  mounted () {
    let self = this
    let routeID = this.$route.params?.id && parseInt(this.$route.params.id)
    this.searchSlides(11).then(() => {
      this.handleVorteile()
      this.handleInfoBox(this.introInfoPage)
      this.$nextTick(() => {
        Object.keys(this.slides).forEach((key) => {
          let b = new Image()
          b.src = self.slides[key].img
          self.imageCache.push(b)
        })
        if (this.$route.params?.id) {
          let slug = Object.keys(this.slides).filter((el) => this.slides[el].slug === this.$route.params?.id)
          if (slug.length > 0) { routeID = slug[0] } else if (!isNaN(this.$route.params?.id)) { routeID = this.$route.params?.id }
        }
        if (routeID && routeID <= Object.keys(this.slides).length) {
          this.showLoader = true
          this.animate = this.isAnimating = false
          setTimeout(() => {
            this.changeSlides(routeID)
          }, 300)
        }
        self.initEvents(true)
      })
    })
    // this.initEvents(true)
    // this.showHome = this.$route.query.hm === undefined && this.$route.params.id === undefined
    this.showHome = true
  },
  created () {
  },
  beforeDestroy () {
    this.clearSlide()
  }
}
</script>
